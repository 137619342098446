import React, { useEffect, useState } from "react";
import PopupContext from "../../helpers/PopupContext";
import { EventListeners } from "../../interfaces";
import ProfileModal from "./profile-modal";
export default function ModalPopup({ id, core }: { id: string; core: any }) {
  const ProfileApp = "Profile";
  const [coordinates, setCoordinates] = useState({
    top: -1,
    left: -1,
    data: null,
    filterKey : null
  });
  const [open, setOpen] = useState<boolean>(false);
  const linkAdapter = core.make("oxzion/link") as any;
  useEffect(() => {
    const e = document.getElementById(id);
    e?.addEventListener(EventListeners.ADD_MODAL_POPUP, setCoords, false);
    return () => {
      e?.removeEventListener(EventListeners.ADD_MODAL_POPUP, setCoords, false);
    };
  }, []);
  const setCoords = (e: CustomEvent) => {
    const {
      target: { clientX, clientY },
      data,
      filterKey
    } = e.detail;
    setOpen(true);
    setCoordinates({
      left: clientX,
      top: clientY,
      data,
      filterKey
    });
  };

  function launchProfileOI(data: any): void {
    setOpen(false);
    const checkRunning = core
      .make("osjs/packages")
      .running()
      .some((app) => app == ProfileApp);
    ProfileModal.setProfileUuid(data);
    !checkRunning && linkAdapter.launchApp({}, "Profile");
    document
      .querySelector<HTMLImageElement>(
        'img[src="/apps/Profile/icon_white.svg"][title="profileWindow"]'
      )
      ?.click();
  }
  return (
    open && (
      <PopupContext
        offset={{ top: coordinates.top, left: coordinates.left }}
        onClose={() => setOpen(false)}
      >
        <ProfileModal
          core={core}
          data={coordinates.data}
          filterKey={coordinates.filterKey}
          onProfileClick={launchProfileOI}
          onClose={() => setOpen(false)}
        />
      </PopupContext>
    )
  );
}

export { ProfileModal };
