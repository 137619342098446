import React from "react";
import PageContent from "./PageContent";
import TabSegment from "./TabSegment";
import { Button } from "@progress/kendo-react-buttons";
import PrintPdf from "./../print/printpdf";
import ActivityLog from "./ActivityLog";
class EntityViewer extends React.Component {
  constructor(props) {
    super(props);
    this.core = this.props.core;
    this.helper = this.core.make("oxzion/restClient");
    this.profileAdapter = this.core.make("oxzion/profile");
    this.profile = this.profileAdapter.get();
    this.appId = this.props.appId;
    this.fileId = this.props.fileId;
    this.proc = this.props.proc;
    this.unmounted = false;
    this.state = {
      content: this.props.content,
      fileData: this.props.fileData,
      entityId: null,
      showAuditLog: false,
      showPDF: false,
      dataReady: false,
      editButton: null,
      entityConfig: null,
      filePanelUuid: generateUUID(),
      tabPanel: "tabpanel-",
      isTabSegment: false,
    };
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  componentDidMount() {
    this.getFileDetails(this.props.fileId).then((fileData) => {
      if (
        fileData.status == "success" &&
        fileData.data &&
        fileData.data.entity_id
      ) {
        this.getEntityPage(fileData.data.entity_id).then((entityPage) => {
          var file = fileData.data.data ? fileData.data.data : fileData.data;

          if (!fileData?.data?.data?.uuid) {
            fileData.data.data.uuid = fileData?.data?.uuid;
          }
          // To be checked why the fileData is not having the uuid
          this.generateEditButton(entityPage.data, fileData.data);
          this.setState({
            entityId: fileData.data.entity_id,
            fileData: file,
            entityConfig: entityPage.data,
            content: this.constructTabs(entityPage.data, fileData.data),
            dataReady: true,
          });
        });
      }
    });
  }

  async getFileDetails(fileId) {
    return this.helper.getMemoizedData(
      this.appId,
      "FILE",
      "/app/" + this.appId + "/file/" + fileId + "/data"
    );
  }

  async getEntityPage(entityId) {
    let fileContent = await this.helper.request(
      "v1",
      "/app/" + this.appId + "/entity/" + entityId + "/page",
      {},
      "get"
    );
    return fileContent;
  }

  generateEditButton(entityConfig, fileData) {
    var fileId;
    let i = 0;
    const toolbarButtons = [];
    if (this.props.fileId) {
      fileId = this.props.fileId;
    } else {
      if (this.state.fileId) {
        fileId = this.state.fileId;
      }
    }
    if (entityConfig && !entityConfig.has_workflow) {
      let formPage = {
        pageContent: [
          {
            type: "Form",
            form_id: entityConfig.form_uuid,
            name: entityConfig.form_name,
            fileId: fileId,
          },
        ],
        title: "Edit",
        icon: "far fa-pencil",
      };
      toolbarButtons.push(
        <Button
          key={i++}
          title={"Edit"}
          className={"btn btn-primary"}
          primary={true}
          onClick={(e) => this.updatePageContent(formPage)}
        >
          <i className={"fa fa-pencil"} />
        </Button>
      );
    }
    if (entityConfig.enablePrint && !this.state.isTabSegment) {
      toolbarButtons.push(
        <Button
          key={i++}
          title={"Print"}
          className={"btn btn-primary"}
          primary={true}
          onClick={() => this.setState({ showPDF: true })}
        >
          <i className={"fa fa-print"} />
        </Button>
      );
    }
    if (entityConfig.enableAuditLog && !this.state.isTabSegment) {
      toolbarButtons.push(
        <Button
          key={i++}
          title={"Audit Log"}
          className={"btn btn-primary"}
          primary={true}
          onClick={() => this.setState({ showAuditLog: true })}
        >
          <i className={"fa fa-history"} />
        </Button>
      );
    }
    if (entityConfig.enableComments != "0" && !this.state.isTabSegment) {
      var commentPage = {
        title: "Comments",
        icon: "far fa-comment",
        pageContent: [{ type: "Comment", fileId: fileId }],
      };
      toolbarButtons.push(
        <Button
          key={i++}
          title={"Comments"}
          className={"btn btn-primary"}
          primary={true}
          onClick={(e) => this.updatePageContent(commentPage)}
        >
          <i className={"fa fa-comment"} />
        </Button>
      );
    }
    toolbarButtons.find((item) => item.title == "Generate Link")
      ? null
      : toolbarButtons.push(
          <Button
            title={"Generate Link"}
            key={i++}
            className={"btn btn-primary"}
            primary={true}
            onClick={(e) => {
              this.props.notif.current.notify(
                "",
                "Link has been copied to clipboard",
                "success",
                undefined,
                2000
              );
              this.core
                .make("oxzion/link")
                .copyToClipboard(
                  this.core.config("ui.url") +
                    "?app=" +
                    entityConfig.app_name +
                    "&fileId=" +
                    fileId
                );
            }}
          >
            <i className={"fa fa-share-alt"} />
          </Button>
        );

    let gridToolbarContent = [
      <div
        className={`display-flex ${
          this.state.isTabSegment ? "task-header-pos-abs" : ""
        }`}
      >
        {toolbarButtons}
      </div>,
    ];

    let ev = new CustomEvent("addcustomActions", {
      detail: { customActions: gridToolbarContent, pageId: this.props.pageId },
      bubbles: true,
    });
    document.getElementById(this.appId + "_breadcrumbParent").dispatchEvent(ev);
  }

  updatePageContent = (config) => {
    let eventDiv = document.getElementById("navigation_" + this.appId);
    let ev2 = new CustomEvent("addPage", {
      detail: config,
      bubbles: true,
    });
    eventDiv.dispatchEvent(ev2);
  };

  constructTabs(page, fileData) {
    var tabs = [];
    var that = this;
    var content = page.content ? page.content : null;
    var finalContentArray = [];
    if (content && content.length > 0) {
      content.map(function (key, index) {
        content[index]["fileId"] = that.fileId;
        finalContentArray.push(content[index]);
      });
    }
    if (finalContentArray && page.enable_view) {
      tabs.push({
        name: "View",
        uuid: that.state.filePanelUuid,
        content: finalContentArray,
      });
    }
    if (page.enable_documents != "0") {
      tabs.push({
        name: "Attachments",
        uuid: generateUUID(),
        content: [
          { type: "DocumentViewer", url: "file/" + this.fileId + "/document" },
        ],
      });
    }
    return (
      <TabSegment
        appId={this.appId}
        core={this.core}
        proc={this.proc}
        fileId={fileData.uuid}
        tabs={tabs}
        pageId={generateUUID()}
        currentRow={fileData}
      />
    );
  }

  render() {
    if (this.state.dataReady) {
      return (
        <div className="contentDiv">
          {this.state.showPDF ? (
            <PrintPdf
              cancel={() => this.setState({ showPDF: false })}
              idSelector={this.state.tabPanel + "" + this.state.filePanelUuid}
              osjsCore={this.core}
            />
          ) : null}
          {this.state.content}
          {this.state.showAuditLog ? (
            <ActivityLog
              cancel={() => this.setState({ showAuditLog: false })}
              appId={this.appId}
              fileId={this.fileId}
              core={this.core}
            />
          ) : null}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default EntityViewer;

var generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
