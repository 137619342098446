import Formio from "formiojs/Formio";
import Base from "formiojs/components/_classes/component/Component";
import components from "./install-forms";
const ComponentInstance = ({ title, group, icon, weight, component }) => {
  return class extends Base {
    constructor(component, options, data) {
      super(component, options, data);
      this.data = data;
      this.form = this.getRoot();
      var that = this;
    }
    static schema() {
      return Base.schema(component);
    }

    static builderInfo = {
      title,
      group,
      icon,
      weight,
      schema: this.schema(),
    };
    render(children) {
      return super.render(children);
    }
  };
};

export default components.map((component) => Formio.registerComponent(component.name, ComponentInstance(component)));
