import React from "react";
import html2pdf from 'html2pdf.js'
import Iframe from 'react-iframe';
import * as KendoReactWindow from "@progress/kendo-react-dialogs";
import "./printpdf.scoped.scss";
import FormRender from "../App/FormRender";
import Swal from "sweetalert2";

export default class PrintPdfNew extends React.Component {
    constructor(props) {
        super(props);
        this.core = this.props.osjsCore;
        this.printSpecificFormID = (this.props.pdfFormContent.properties.printSpecificFormID) || undefined;
        this.printFileName =  (this.props.pdfFormContent.properties.pdfTitle) || undefined;
        if (!this.props.pdfFormContent.properties.printSpecificFormID) {
            this.domElementId = this.props.idSelector;
        }
        this.cssClass = this.props.cssClass;
        this.loader = this.core.make("oxzion/splash");
        this.state = {
            showImages: true,
            pageFormat: 'portrait',
            selectedFrame: undefined,
            includeImg: true,
            selectedTab: "",
            printMode: false,
            formTemplate: undefined
        };
        this.helper = this.core.make("oxzion/restClient");
        this.notif = this.props.notif;
    }

    componentDidMount() {
        if (this.printSpecificFormID) {
            this.processPrintSpecificFormPdf();
        }
    }

    resetFrameSrc() {
        var frames = document.getElementsByTagName('iframe');
        for (var i = 0; i < frames.length; i++) {
            frames[i].src = 'about:blank';
        }
    }

    async getForm(formId) {
        // call to api using wrapper
        return await this.helper.request("v1", this.props.appUrl + "/form/" + formId, {}, "get");
    }

    processPrintSpecificFormPdf() {
        // Get form template, add styling changes and render 
        this.getForm(this.printSpecificFormID).then((response) => {
            if (response.status == "success") {
                this.setState({ formTemplate: JSON.parse(response.data.template) })
                this.domElementId = "generalLiabilityPrintForm";
                setTimeout(() => {
                    this.processPdf();
                }, 10000);
            }
        });
    }

    processPdf(fileType = 'pdf') {
        // let that = this;
        // that.loader.show();

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            console.error("PDF Generation not supported!");
            return;
        }

        var element = document.getElementById('generalLiabilityPrintForm');
        var opt = {
            margin: [0.2, 0.5, 0.2, 0.5], //top,left,bottom,right
            filename: this.printFileName + Date.now() + '.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
            pagebreak: { avoid: [".formio-component", ".html-page-break"]}
        };

        this.notif.current.notify(
            "In Progress",
            "Please wait, your PDF file will download automatically.",
            "success",
            "bottom-right",
            15000);

        // this.props.cancel();
        // this.loader.destroy();

        // New Promise-based usage:
        var worker = html2pdf();
        worker.set(opt).from(element).save();

        setTimeout(() => {
            document.querySelector('.html2pdf__overlay').style.display = "none";
        }, 200);

    }

    render() {
        return (
            <div id="printPDFFile" style={{ display: "none", overflow: "scroll" }}>
                {/*   */}
                <div className="printWindow">
                    {this.state.formTemplate &&
                        <div id="generalLiabilityPrintForm">
                            <FormRender
                                key={"abc"}
                                core={this.core}
                                postSubmitCallback={(formData) =>
                                    console.log("123")
                                }
                                notif={this.notif}
                                data={this.props.formData}
                                content={this.state.formTemplate}
                            />
                        </div>}
                </div>
            </div>);
    }
}