import React, { useEffect, useState } from "react";
import { CoreBase } from "../../interfaces";
import "../App/Styles/profile-modal.scss";
interface Props {
  onProfileClick?: (data: any) => void;
  data: string;
  filterKey: string;
  core: CoreBase;
  onClose?: () => void;
}
function ProfileModalComp({ data, onProfileClick, core, onClose, filterKey}: Props) {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const rest = core.make<any>("oxzion/restClient");
  useEffect(() => {
    rest
      .request("v1", `/user/${data}`, {}, "get")
      .then(({ status, data }) => {
        const isSuccess = status === "success";
        setLoading(false);
        if (!isSuccess) return onClose?.();
        setProfile((isSuccess && data) || null);
      })
      .catch((e) => {
        setLoading(false);
        onClose?.();
      });
  }, []);
  return (
    <>
      {loading && <div className="k-loading-image"></div>}
      {/* {profile && ( */}
        <div className="profile-modal-custom">
          <div className="flex flex-column gap-5">
            <div>
              <img src={profile?.icon} loading="lazy" />
            </div>
            <div className="profile-modal-custom_details text-center">
              <p
                className="k-cursor-pointer"
                style={{fontSize : '1.5rem'}}
                onClick={() => onProfileClick?.({data, filterKey})}
              >
                {profile?.username || '...'}
              </p>
              <p className="m-0">{profile?.interest}</p>
            </div>
          </div>
        </div>
      {/* )} */}
    </>
  );
}
export default class ProfileModal extends React.Component<Props, any> {
  private static profileData: any;
  public static profileUuidCallback: (s: string) => void;
  constructor(props) {
    super(props);
  }
  render(): React.ReactNode {
    return <ProfileModalComp {...this.props} />;
  }

  static setProfileUuid(data: any) {
    this.profileUuidCallback?.(data);
    this.profileData = data;
  }

  static getProfileUuid(): any {
    return this.profileData;
  }
}
