import assignedComponent from "./custom-forms/assigned-component";
export default [
  {
    component: assignedComponent, //actual json components
    name: "assignedComponent", //name of the component on left menu
    title: "Assigned", // label
    group: "Custom", //in which menu group this component should belong to
    icon: "fa fa-user", //fontawsome
    weight: 70,
  },
];
